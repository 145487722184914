<template>
    <comp-table title="终端群组营业报表" ref="comp_table" :columns="columns" table-api="/gateway/apps/o2o/api/terminal/goods/getTerminalGroupSalesReport" :dataBefore="onDataBefore" @on-change-search="onChangeSearch">
        <template v-slot:search="data">
            <Select v-model="data.search.terminalGroupId" filterable style="width:200px" transfer placeholder="群组">
                <Option v-for="item in groups" :value="item.id" :key="'groups-' + item.id">{{ item.groupName }}</Option>
            </Select>
            <Select v-model="data.search.terminalUuid" filterable style="width:200px" transfer placeholder="终端机">
                <Option v-for="item in terminals" :value="item.id" :key="'terminals-' + item.id">{{ item.terminalName }}</Option>
            </Select>
            <RadioGroup v-model="data.search.type" type="button">
                <Radio label="全部"></Radio>
                <Radio label="本年"></Radio>
                <Radio label="本月"></Radio>
                <Radio label="本日"></Radio>
                <Radio label="自定义"></Radio>
            </RadioGroup>
            <DatePicker v-if="isCustomize" v-model="data.search.date" type="daterange" style="width: 250px" placeholder="时间段"></DatePicker>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            groups: null,
            terminals: null,

            isCustomize: false,

            columns: [
                {
                    title: "供应商",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "商品",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "数量",
                    key: "num",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "销售额",
                    key: "sales",
                    minWidth: 200,
                    align: "center",
                },
            ],

            watch: {
                type: v => {
                    this.isCustomize = v === "自定义"
                },
                terminalGroupId: v => {
                    this.getTerminal(v)
                },
            },
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        getTerminal(id) {
            if (!id) return (this.terminals = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listTerminalByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.terminals = res.dataList
                }
            })
        },

        // 监听数据前置事件
        onDataBefore(data) {
            const res = new Object(null)

            if (data.type == "自定义") {
                if (!data.date) {
                    this.$Message.error({
                        content: "请选择时间",
                        background: true,
                    })

                    return false
                }

                res.startDate = data.date[0]
                res.endDate = data.date[1]
            } else if (data.type != "全部") {
                res.date = { 本月: "MONTH", 本年: "YEAR", 本日: "DAY" }[data.type]
            }

            data.terminalGroupId && (res.terminalGroupId = data.terminalGroupId)
            data.terminalUuid && (res.terminalUuid = data.terminalUuid)

            return res
        },

        onChangeSearch(evt) {
            const { key, value } = evt.value

            if (key === "type") {
                this.isCustomize = value === "自定义"
            } else if (key === "terminalGroupId") {
                this.getTerminal(value)
            }
        },
    },
}
</script>
<style lang="less"></style>
